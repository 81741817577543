var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"min-width":"500px","width":"80vw","fullscreen":_vm.mobileBreakpoints()},on:{"click:outside":function($event){return _vm.resetRasterForm()}},model:{value:(_vm.table_chart.openRasterLayerTableChartPopup),callback:function ($$v) {_vm.$set(_vm.table_chart, "openRasterLayerTableChartPopup", $$v)},expression:"table_chart.openRasterLayerTableChartPopup"}},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[(_vm.table_chart.isTable)?_c('v-card-title',{staticClass:"green--text text--accent-4 d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Marker.tab')))]),_c('v-icon',{attrs:{"color":"red","size":"30"},on:{"click":function($event){return _vm.resetRasterForm()}}},[_vm._v(" mdi-close ")])],1):_c('v-card-title',{staticClass:"green--text text--accent-4 d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Marker.graph')))]),_c('v-icon',{attrs:{"color":"red","size":"30"},on:{"click":function($event){return _vm.resetRasterForm()}}},[_vm._v(" mdi-close ")])],1),(_vm.renderContent)?_c('v-card-text',[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.rasterLayers,"item-text":_vm.concatenateWorkspaceLayer,"label":_vm.$t('ServiceCard.layers'),"chips":"","multiple":"","counter":"2","return-object":"","item-color":"RMprimary","color":"RMprimary"},on:{"input":_vm.limiterSelect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(((item.ws_name) + ":" + (item.layer_name) + "_serviceID:" + (item.service_id) + ".label")))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","color":"RMprimary","text-color":"white","close":""},on:{"click:close":function($event){_vm.chipSelectedRasterLayers.splice(_vm.chipSelectedRasterLayers.findIndex(function (chip) { return chip.layer_name === item.layer_name; }), 1)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(((item.ws_name) + ":" + (item.layer_name) + "_serviceID:" + (item.service_id) + ".label"))))])])]}}],null,false,141140545),model:{value:(_vm.chipSelectedRasterLayers),callback:function ($$v) {_vm.chipSelectedRasterLayers=$$v},expression:"chipSelectedRasterLayers"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#0da344","small":"","outlined":"","disabled":_vm.chipSelectedRasterLayers.length > 2 || _vm.chipSelectedRasterLayers.length <= 0},on:{"click":function($event){return _vm.updateRasterTableChart(_vm.chipSelectedRasterLayers)}}},[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.confirm'))+" ")])],1),_c('div',[_c('div',{staticClass:"mt-2 mb-2 d-flex flex-column"},[_c('div',{staticClass:"mb-4 d-flex flex-column"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('Ticketing.address'))+":")]),_vm._v(" "+_vm._s(_vm.map.placeAddress))]),_c('div',[_c('span',{staticClass:"mr-2"},[_c('b',[_vm._v(_vm._s(_vm.$t('Ticketing.latitude'))+":")]),_vm._v(" "+_vm._s(_vm.map.coordsLonLat[1]))]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('Ticketing.longitude'))+":")]),_vm._v(" "+_vm._s(_vm.map.coordsLonLat[0]))])])]),(_vm.table_chart.isTable)?_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"auto"},attrs:{"color":"#4caf50","small":"","outlined":"","max-width":"min-content"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.export'))+" "),(_vm.table_chart.isTable)?_c('v-icon',{staticClass:"ma-1",attrs:{"color":"#4caf50"}},[_vm._v(" mdi-table ")]):_c('v-icon',{staticClass:"ma-1",attrs:{"color":"#4caf50"}},[_vm._v(" mdi-chart-line ")])],1)]}}],null,false,144491024)},[_c('v-list',_vm._l((_vm.exportTableExtensions),function(item){return _c('v-list-item',{key:item.type,attrs:{"color":item.color},on:{"click":function($event){return _vm.exportTable(item.type)}}},[_c('v-list-item-icon',{staticClass:"mr-1 ml-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-file-export ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{style:(("color: " + (item.color)))},[_vm._v(_vm._s(item.type))])])],1)],1)}),1)],1):_vm._e()],1),_c('v-alert',{staticClass:"mt-4",attrs:{"dense":"","type":"warning","icon":"mdi-alert-outline","transition":"scale-transition","dismissible":""},model:{value:(_vm.wpsLimitsExceeded),callback:function ($$v) {_vm.wpsLimitsExceeded=$$v},expression:"wpsLimitsExceeded"}},[_vm._v(" "+_vm._s(_vm.$t('Table.wpsLimitsExceeded'))+" ")]),(_vm.table_chart.isTable)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"requests-table","fixed-header":"","height":_vm.isLandscape() ? '100%' : (_vm.table_chart.rasterTableItems.length > 8 ? '450px': ''),"headers":_vm.table_chart.rasterTableHeaders,"items":_vm.table_chart.rasterTableItems,"items-per-page":10,"mobile-breakpoint":0,"footer-props":{
                itemsPerPageAllText: _vm.$t('Table.all'),
                itemsPerPageText: _vm.$t('Table.rowsPerPage'),
                pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
              },"no-data-text":_vm.$t('Table.noData')},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.time.includes('/') ? item.time : _vm.formatDate(item.time))+" ")]}}],null,true)})],1):_c('div',[(_vm.renderCharts)?_c('div',[_c('div',{staticClass:"mb-2 d-flex justify-space-between align-center"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.mandatoryChart),callback:function ($$v) {_vm.mandatoryChart=$$v},expression:"mandatoryChart"}},[_c('v-radio',{attrs:{"label":_vm.$t('Graph.line'),"ripple":true,"value":"line","color":"RMprimary"}}),_c('v-radio',{attrs:{"label":_vm.$t('Graph.bar'),"ripple":true,"value":"bar","color":"RMprimary"}})],1),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#4caf50","small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.export'))+" "),_c('v-icon',{staticClass:"ma-1",attrs:{"color":"#4caf50"}},[_vm._v(" mdi-chart-line ")])],1)]}}],null,false,1003246941)},[_c('v-list',_vm._l((_vm.exportChartExtensions),function(item){return _c('v-list-item',{key:item.type,attrs:{"color":item.color},on:{"click":function($event){return _vm.exportChart(item.type)}}},[_c('v-list-item-icon',{staticClass:"mr-1 ml-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-file-export ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{style:(("color: " + (item.color)))},[_vm._v(_vm._s(item.type))])])],1)],1)}),1)],1)],1),(_vm.mandatoryChart === 'line')?_c('line-chart',{ref:"chart",attrs:{"datasets":_vm.table_chart.rasterVectorChartDataSetLine},on:{"chart_data":function($event){_vm.chartData = $event}}}):_c('bar-chart',{ref:"chart",attrs:{"datasets":_vm.table_chart.rasterVectorChartDataSetBar},on:{"chart_data":function($event){_vm.chartData = $event}}})],1):_vm._e()])],1)]):_c('v-spacer',{staticClass:"pb-16"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }